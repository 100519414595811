@import '../settings';

.resource-card {
  background: $white;
  border-radius: 0 0 1rem 1rem;
  border-top: 10px solid transparent;

  &.articles {
    border-top-color: #c8f5f2; // scss-lint:disable ColorVariable
  }

  &.courses {
    border-top-color: #fbf2b8; // scss-lint:disable ColorVariable
  }

  &.screeners {
    border-top-color: #ebf3a7; // scss-lint:disable ColorVariable
  }
}
