@import '../settings';

section {
  background: center center no-repeat;
  background-size: cover;
  padding-bottom: 4rem;
  padding-top: 4rem;

  &.dark {
    background: lighten(get-color(warning), 47.5%);
  }

  &:first-child {
    padding-top: 8rem;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
