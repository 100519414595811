@import '../settings';

header {
  align-items: center;
  background-image: url('../../images/headers/home.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid $light-gray;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem map-get($grid-container-padding, small);
  text-align: center;
  transition: background-image 1s ease-in-out;


  @include breakpoint(medium) {
    padding: 8rem map-get($grid-container-padding, medium);
  }

  .resource-tabs {
    bottom: -1px;
    box-sizing: border-box;
    width: 100%;

    @include breakpoint(large) {
      width: auto;
    }

    .tabs {
      border: 0;
      display: flex;
      flex: 1;
      flex-direction: row;
      width: 100%;

      @include breakpoint(large) {
        display: block;
      }
    }

    .tabs-title {
      background: linear-gradient(180deg, rgba($primary-color, .05) 0%, rgba($primary-color, .3) 100%);
      border-right: 1px solid $white;
      display: flex;
      flex: 1;

      // scss-lint:disable NestingDepth
      &:first-child {
        border-left: 1px solid $white;
      }

      // scss-lint:disable NestingDepth SelectorDepth
      a {
        align-items: center;
        border-bottom: 7px solid rgba($primary-color, .3);
        border-top: 7px solid transparent;
        color: get-color(info);
        display: flex;
        font-weight: bold;
        justify-content: center;
        width: 100%;

        @include breakpoint(medium only) {
          padding: 1rem 1.25rem;
        }

        @include breakpoint(small only) {
          font-size: 12px;
          padding: .5rem .25rem;
        }
      }
    }

    .is-active {
      // scss-lint:disable NestingDepth SelectorDepth
      a {
        border-bottom-color: $primary-color;
      }
    }
  }

  h1,
  .h1 {
    font-weight: bold;
  }
}
