@import 'src/assets/stylesheets/settings';

$logo-width: 136px;

nav {
  background: rgba($black, .33);
  box-shadow: 0 2px 26px $dark-gray;
  left: 0;
  position: fixed;
  top: 0;
  transition: all .5s ease;
  width: 100%;
  z-index: 999999;

  .logo-wrapper {
    margin-right: rem-calc(58px) + 1rem;
    position: relative;

    @include breakpoint(large) {
      margin-right: rem-calc(136px) + 1rem;
    }
  }

  .logo {
    // scss-lint:disable ImportantRule
    margin: 0;
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
    position: absolute;
    transition: all .5s ease;
    width: 4.375rem;

    @include breakpoint(large) {
      background: get-color(primary);
      padding: 1rem;
      width: rem-calc(136px);
    }
  }

  &.primary {
    background: get-color(primary);

    .logo {
      @include breakpoint(large) {
        background: none;
        padding: .5rem;
        width: rem-calc(72px);
      }
    }
  }
}
