@import '../settings';

.sidebar {
  background: $white;
  box-shadow: -20px 0 50px -50px $dark-gray;

  .course-sidebar {
    border-top: 1px solid $medium-gray;
  }
}
