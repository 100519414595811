@import '../settings';

.off-canvas {
  height: 100%;
  left: auto;
  max-width: 60%;
  min-width: 265px;
  overflow: scroll;
  padding-top: 3rem;
  right: 0;
  text-align: right;
  transform: translateY(0);
  width: auto;
  z-index: 1000000;

  .off-canvas-content {
    background: $white;
    box-shadow: 0 3px 15px rgb(10 10 10 / 20%);
    left: 15px;
    padding-top: 3rem;
    position: fixed;
    top: 0;
  }

  .close-button {
    margin: .5rem 1rem;
  }

  li {
    border-bottom: 1px solid $light-gray;
    margin-bottom: 1px;

    &.divider {
      background: $primary-color;
      border-bottom: 0;
      height: 1px;
      margin-bottom: 1px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  a {
    background: $white;
    color: $primary-color;
    font-size: $lead-font-size;
    font-weight: bold;
    padding: 1.25rem;

    &:hover {
      color: get-color(primary);
    }
  }
}
