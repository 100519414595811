@import '../settings';

.title-bar {
  .title-bar-left {
    height: 3rem;
  }

  a {
    color: $white;
    margin: 0 .75rem;
    padding: 1.5rem 0;
    position: relative;
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
    }

    &::before {
      background: none;
      border-radius: 2px;
      bottom: 0;
      content: '';
      height: 7px;
      left: 0;
      position: absolute;
      transition: all .5s ease;
      width: 100%;
    }

    &.active {
      // scss-lint:disable NestingDepth
      &::before {
        background: get-color(primary);
      }

      &:hover {
        &::before {
          background: $white;
        }
      }
    }

    &:hover {
      // scss-lint:disable NestingDepth
      &::before {
        background: $white;
      }
    }
  }
}

.primary {
  .title-bar {
    a {
      // scss-lint:disable NestingDepth
      &.active {
        &::before {
          background: $white;
        }
      }
    }
  }
}
