@import '../settings';

.is-dropdown-submenu-parent {
  &:hover {
    ul {
      display: block;

      // scss-lint:disable NestingDepth
      li {
        &:hover {
          background: $light-gray;
        }
      }

      a {
        color: get-color(primary);

        &::before {
          content: none;
        }
      }
    }
  }
}
