@import 'src/assets/stylesheets/settings';

.course-content {
  padding: $global-padding;
}

.tabs {
  border: 0;

  .tabs-title {
    button {
      color: $primary-color;
      padding: 20px;
    }

    &.is-active {
      background-color: $light-gray;
      border-bottom: 5px solid $primary-color;
      box-sizing: border-box;
    }
  }
}

.buttons {
  padding: $global-padding;
}

.sections-list {
  a {
    color: $black;
  }
}

.section {
  font-weight: 600;

  &.selected {
    a {
      color: $primary-color;
      font-weight: 700;
    }
  }
}

.page {
  a {
    //color: $dark-gray;
  }

  &.selected a {
    color: $black;
    font-weight: bold;
  }
}
