@import '../settings';

input {
  &[type="radio"] {
    appearance: none;
    background-color: $white;
    border: .15rem solid $primary-color;
    border-radius: 50%;
    color: $primary-color;
    display: inline-grid;
    font: inherit;
    height: 1.15rem;
    place-content: center;
    transform: translateY(-.125rem);
    width: 1.15rem;

    &:checked::before {
      background: $primary-color;
      transform: scale(1);
    }

    + label {
      margin-left: $global-margin;
    }

    &::before {
      border-radius: 50%;
      box-shadow: inset 1em 1em $primary-color;
      content: '';
      height: .65rem;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      width: .65rem;
    }
  }
}

label {
  cursor: pointer;
}
