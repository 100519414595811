@import 'settings';

.white-background-color {
  background: $white;
}

.white-color {
  color: $white;
}

.black-color {
  color: $black;
}

.teal-color {
  color: get-color(info);
}

.dark-gray-color {
  color: $dark-gray;
}

.gradient-bg {
  background: linear-gradient(180deg, $white 0%, rgba($primary-color, .15) 100%);
}

.success-bg-color {
  background: lighten(get-color(success), 18%);
}

.dark-success-color {
  color: darken(get-color(success), 80%);
}

.disable-scrolling {
  overflow: hidden;
}
