@import '../settings';

.lds-roller {
  display: inline-block;
  height: 80px;
  position: relative;
  width: 80px;


  div {
    animation: lds-roller 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
    transform-origin: 40px 40px;
  }

  div::after {
    background: get-color(alert);
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 7px;
    margin: -4px 0 0 -4px;
    position: absolute;
    width: 7px;
  }

  div:nth-child(1) {
    animation-delay: -.036s;
  }

  div:nth-child(1)::after {
    left: 63px;
    top: 63px;
  }

  div:nth-child(2) {
    animation-delay: -.072s;
  }

  div:nth-child(2)::after {
    left: 56px;
    top: 68px;
  }

  div:nth-child(3) {
    animation-delay: -.108s;
  }

  div:nth-child(3)::after {
    left: 48px;
    top: 71px;
  }

  div:nth-child(4) {
    animation-delay: -.144s;
  }

  div:nth-child(4)::after {
    left: 40px;
    top: 72px;
  }

  div:nth-child(5) {
    animation-delay: -.18s;
  }

  div:nth-child(5)::after {
    left: 32px;
    top: 71px;
  }

  div:nth-child(6) {
    animation-delay: -.216s;
  }

  div:nth-child(6)::after {
    left: 24px;
    top: 68px;
  }

  div:nth-child(7) {
    animation-delay: -.252s;
  }

  div:nth-child(7)::after {
    left: 17px;
    top: 63px;
  }

  div:nth-child(8) {
    animation-delay: -.288s;
  }

  div:nth-child(8)::after {
    left: 12px;
    top: 56px;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
