@import '../settings';

footer {
  background: $white;
  box-shadow: inset 0 0 1px $medium-gray;
  padding: 2rem;
  text-transform: uppercase;

  a {
    color: $dark-gray;
  }
}
