@import './foundation_and_overrides';
@import 'utilities';
@import 'foundation/cards';
@import 'foundation/dropdown';
@import 'foundation/forms';
@import 'foundation/menu-icon';
@import 'foundation/off-canvas';
@import 'foundation/title-bar';
@import 'global/footer';
@import 'global/header';
@import 'global/image';
@import 'global/resource-card';
@import 'global/section';
@import 'global/spinner';
@import 'global/nav';
@import 'global/topic-items';
@import 'global/modal';
@import 'screens/courses';
@import 'screens/screeners';
@import 'screens/home';

html,
body {
  height: 100%;
  // Removed smooth scroll as it is causing problems with hash linking different sections of the page.
  // The link it not pointing to the correct section but is stopping a section ahead.
  //scroll-behavior: smooth;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
}

body {
  background: radial-gradient(circle 1039px at 45.5% 27.72%, #f3f8e61c 0%, #8ee6e71c 60%, #1dc4e91c 100%);
}

