@import '../settings';

.menu-icon {
  color: $dark-gray;
  height: 20px;
}

.cross-button {
  color: $dark-gray;
  height: 20px;
}
