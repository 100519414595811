@import '../settings';

.card {
  &.has-background-image {
    background: center center no-repeat;
    background-size: cover;
    color: $black !important; // scss-lint:disable ImportantRule
    cursor: pointer;
    position: relative;

    &::after {
      background-image: linear-gradient(180deg, get-color(primary) 0%, get-color(success) 100%);
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: all .25s ease-in-out;
      width: 100%;
    }

    &:hover {
      // scss-lint:disable NestingDepth
      &::after {
        opacity: .5;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
    }

    .card-section {
      display: flex;
      flex-direction: column;
    }

    .card-divider {
      background: none;
    }
  }
}
