@import '../settings';

.screener {
  h1 {
    text-transform: uppercase;
  }

  .time-estimate {
    align-items: center;
    aspect-ratio: 1 / 1;
    background-color: $light-gray;
    border: 5px solid $white;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    width: 100px;
  }

  .error-message {
    color: get-color(alert);
    text-align: center;
  }
}

.tooltip {
  background: get-color(info);
  display: inline-block;
  font-size: $small-font-size;
  left: var(--left);
  line-height: 1.25;
  margin-bottom: 17px;
  padding: .5rem;
  position: relative;
  transform: translate(-50%, 0%);
  z-index: 1;

  &::after {
    border-color: get-color(info) transparent transparent;
    border-style: solid;
    border-width: 20px;
    content: '';
    left: 50%;
    margin-top: -1px;
    position: absolute;
    top: 100%;
    transform: translate(-50%, 0%);
  }
}

.gradient-placement {
  background: lighten(get-color(info), 10%);
  content: '';
  height: 100%;
  left: var(--left);
  position: absolute;
  transform: translate(-50%, 0%);
  width: 1px;
}

.gradient {
  background: linear-gradient(90deg, get-color(success) 0%, get-color(primary) 100%);
  height: 4rem;
}
